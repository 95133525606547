
 // @ is an alias to /src
import Navigation from '@/components/partials/Navigation.vue';
import Aside from '@/components/partials/Aside.vue';
import Footer from '@/components/partials/Footer.vue';
import { defineComponent, ref, reactive, computed } from 'vue';
import { useStore } from 'vuex';
export default defineComponent({
  name: "Registration",
  components: {
    Navigation,
    Aside,
    Footer
  },
  setup() {
    const store = useStore();
    const links = reactive([
      {
        page: "Registration",
        name: "Register",
        url: "account/registration"
      },
      {
        page: "Registration",
        name: "Login",
        url: "account/registration"
      },
      {
        page: "Registration",
        name: "Reset Password",
        url: "account/registration"
      },
    ]);
    const user = reactive({
      displayName: "John Doe",
      phoneNumber: "+123456789",
      email: "john.doe@email.com",
      password: "********",
      confirm_password: "********",
    });

    const name = ref("Account");
    const nav = computed(() => store.getters.nav);

    return { name, nav, user, links };
  },
});
